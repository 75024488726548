import ApiClient from './ApiClient';

class CampaignsAPI extends ApiClient {
  constructor() {
    super('campaigns', { accountScoped: true });
  }

  get(since = '', until = '', status = '', page = '', campaignType = '') {
    return axios.get(this.url, {
      params: {
        by_status: status,
        since,
        until,
        page,
        campaign_type: campaignType,
      },
    });
  }

  downloadError(id) {
    return axios.get(`${this.url}/${id}/download_error`);
  }
}

export default new CampaignsAPI();
