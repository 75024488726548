import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const Index = () => import('./Index.vue');
const OneOffCampaignForm = () => import('./OneOffCampaignForm.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: {
        headerTitle: 'CAMPAIGN.ONGOING.HEADER',
        icon: 'arrow-swap',
      },
      children: [
        {
          path: '',
          redirect: to => {
            return { name: 'ongoing_campaigns', params: to.params };
          },
        },
        {
          path: 'ongoing',
          name: 'ongoing_campaigns',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/campaigns'),
      component: SettingsContent,
      props: params => {
        const showSettingsHeader = !['create_one_off', 'edit_one_off'].includes(
          params.name
        );
        return {
          headerTitle: 'CAMPAIGN.ONE_OFF.HEADER',
          icon: 'sound-source',
          showSettingsHeader,
          keepAlive: false,
        };
      },
      children: [
        {
          path: 'one_off',
          name: 'one_off',
          meta: {
            permissions: ['administrator'],
          },
          component: Index,
        },
        {
          path: 'create_one_off',
          name: 'create_one_off',
          meta: {
            permissions: ['administrator'],
          },
          component: OneOffCampaignForm,
        },
        {
          path: 'edit/:campaign_id',
          name: 'edit_one_off',
          meta: {
            permissions: ['administrator'],
          },
          props: route => {
            return { campaignId: route.params.campaign_id };
          },
          component: OneOffCampaignForm,
        },
      ],
    },
  ],
};
