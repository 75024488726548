<script>
export default {
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    headerContentValue: {
      type: String,
      default: '',
    },
    contentHtml: {
      type: Boolean,
      default: false,
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
};
</script>

<!-- eslint-disable vue/no-unused-refs -->
<!-- Added ref for writing specs -->
<template>
  <div class="flex flex-col items-start px-8 pt-8 pb-0">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <h2
      data-test-id="modal-header-title"
      class="text-base font-semibold leading-6 text-slate-800 dark:text-slate-50"
    >
      {{ headerTitle }}
    </h2>
    <p
      v-if="headerContent"
      data-test-id="modal-header-content"
      class="w-full mt-2 text-sm leading-5 break-words text-slate-600 dark:text-slate-300"
    >
      <span v-if="contentHtml" v-html="headerContent" />
      <span v-else> {{ headerContent }}</span>
      <span
        v-if="headerContentValue"
        class="text-sm font-semibold text-slate-600 dark:text-slate-300"
      >
        {{ headerContentValue }}
      </span>
    </p>
    <slot />
  </div>
</template>
